<template>
  <ContentSection :spacing="false">
    <section class="flex items-center justify-between px-3 py-4 mt-2 bg-white">
      <TitlePlus
        :title="
          $t('components.riderDetailsManagement.diveIntoTab.points.headline')
        "
        :hide-plus="true"
      />
      <DateRangePicker
        @apply-date="handleApplyDate($event)"
        @cancel-date="handleCancelDate($event)"
      />
    </section>

    <section
      class="flex flex-col px-4 mt-5 space-y-5 lg:items-center lg:space-y-0 lg:space-x-5 lg:flex-row"
    >
      <summary-card
        :title="
          $t(
            'components.riderDetailsManagement.diveIntoTab.points.summary.totalPoints'
          )
        "
        :value="indexMetaData.summary.total"
        variant="gray"
        size="large"
      />

      <summary-card
        :title="
          $t(
            'components.riderDetailsManagement.diveIntoTab.points.summary.positivePoints'
          )
        "
        :value="indexMetaData.summary.positive"
        variant="gray"
        size="large"
      />

      <summary-card
        :title="
          $t(
            'components.riderDetailsManagement.diveIntoTab.points.summary.negativePoints'
          )
        "
        :value="indexMetaData.summary.negetive"
        variant="gray"
        size="large"
      />

      <summary-card
        :title="
          $t(
            'components.riderDetailsManagement.diveIntoTab.points.summary.reducedToZero'
          )
        "
        :value="indexMetaData.summary.reduce_to_zero"
        variant="gray"
        size="large"
      />
    </section>

    <section class="grid grid-cols-1 px-4 mt-10 md:grid-cols-2">
      <div>
        <OtoSearch v-model="search" />
      </div>
      <div class="flex md:justify-end ">
        <ExportAsDropdown />
      </div>
    </section>
    <div
      v-if="viewPermissionStatus"
      class="py-5 font-bold text-center text-gray-600"
    >
      You don't have permission to see data. Please Ask your admin to give you
      permission for this screen.
    </div>

    <fsTableContainer v-else class="vld-parent">
      <loading :active.sync="isLoading" :is-full-page="false" />

      <fsTable>
        <fsTableHead>
          <fsTableHeadItem
            v-for="(item, index) in currentTableHeaders"
            :key="`fs-table-header-${index}`"
            :item="item"
            @apply-sorting="handleSorting($event)"
          />
        </fsTableHead>

        <fsTableRow
          v-for="(item, index) in indexData"
          :key="`fs-table-row-${index}`"
        >
          <fsTableRowItem>
            {{ $truncate(item.id, -5) }}
          </fsTableRowItem>

          <fsTableRowItem>
            {{ item.title }}
          </fsTableRowItem>

          <fsTableRowItem>
            <router-link
              v-if="item.trip"
              :to="{ name: 'ViewTrip', params: { id: item.trip } }"
              :key="`trip-hyperlink-${item.id}`"
              target="_blank"
            >
              <span class="text-blue-500">
                {{ $truncate(item.trip, -5) }}
              </span>
            </router-link>
            <div v-else :key="`trip-hyperlink-${item.id}`">
              --
            </div>
          </fsTableRowItem>

          <fsTableRowItem>
            <router-link
              v-if="item.report"
              :to="{ name: 'ReportDetails', params: { id: item.report } }"
              :key="`report-hyperlink-${item.id}`"
            >
              <span class="text-blue-500">
                {{ $truncate(item.report, -5) }}
              </span>
            </router-link>
            <div v-else :key="`report-hyperlink-${item.id}`">
              --
            </div>
          </fsTableRowItem>

          <fsTableRowItem>
            {{
              getFormattedDateTime(item.date, 'D MMM, YY hh:mm:ss a') || '--'
            }}
          </fsTableRowItem>

          <fsTableRowItem>
            <div
              v-if="item.reduce_to_zero"
              :key="`point-${item.id}`"
              :class="`text-oRed`"
            >
              Reduced to zero
            </div>
            <div
              v-else
              :key="`point-${item.id}`"
              :class="{ 'text-oRed': Number(item.point) < 0 }"
            >
              {{ item.point }}
            </div>
          </fsTableRowItem>
        </fsTableRow>
      </fsTable>

      <fs-table-foot
        :total-items="indexMetaData.count.total"
        :pagination-summary="paginationSummary"
      />
    </fsTableContainer>
  </ContentSection>
</template>

<script>
// import { api as RiderUserApi } from '@/config/rider-user.config'
import isLoadingMixin from '@/mixins/isLoadingMixin'
import queryStringMixin from '@/mixins/queryStringMixin'
import queryStringSearchMixin from '@/mixins/queryStringSearchMixin'
import queryStringFiltersMixin from '@/mixins/queryStringFiltersMixin'
import getIndexDataMixin from '@/mixins/getIndexDataMixin'
import fsTableUiMixin from '@/mixins/fsTableUiMixin'
import { getFormattedDateTime } from '@/utils/datetime'
export default {
  name: 'ViewRiderUserPoints',
  mixins: [
    isLoadingMixin,
    queryStringMixin,
    queryStringSearchMixin,
    queryStringFiltersMixin,
    getIndexDataMixin,
    fsTableUiMixin,
  ],
  components: {
    SummaryCard: () => import('@/components/cards/SummaryCard'),
    DateRangePicker: () =>
      import('@/components/picker/date-range/DateRangePicker'),
    ContentSection: () => import('@/components/layout/ContentSection'),
    OtoSearch: () => import('@/components/ui/OtoSearch'),
    ExportAsDropdown: () => import('@/components/dropdown/ExportAsDropdown'),
    TitlePlus: () => import('@/components/ui/TitlePlus'),
  },
  data() {
    return {
      indexMetaData: {
        summary: {
          total: 0,
          negetive: 0,
          positive: 0,
          reduce_to_zero: 0,
        },
        count: {
          total: 0,
          page: 0,
        },
      },
      currentTableHeaders: [
        {
          name: this.$t(
            'components.riderDetailsManagement.diveIntoTab.points.table.columns.id'
          ),
          width: '10%',
          field: 'id',
          sort: false,
          order: '',
        },
        {
          name: this.$t(
            'components.riderDetailsManagement.diveIntoTab.points.table.columns.pointType'
          ),
          width: '10%',
          field: 'tax',
          sort: null,
          order: '',
        },
        {
          name: this.$t(
            'components.riderDetailsManagement.diveIntoTab.points.table.columns.tripId'
          ),
          width: '10%',
          field: 'user',
          sort: null,
          order: '',
        },
        {
          name: this.$t(
            'components.riderDetailsManagement.diveIntoTab.points.table.columns.reportId'
          ),
          width: '10%',
          field: 'user',
          sort: false,
          order: '',
        },
        {
          name: this.$t(
            'components.riderDetailsManagement.diveIntoTab.points.table.columns.date'
          ),
          width: '15%',
          field: 'date',
          sort: true,
          order: '',
        },
        {
          name: this.$t(
            'components.riderDetailsManagement.diveIntoTab.points.table.columns.totalPoints'
          ),
          width: '15%',
          field: 'payment',
          sort: true,
          order: '',
        },
      ],
      indexDataEndpoint: this.$config.orgUser.api.pointIndex(
        this.$route.params.id
      ),
    }
  },
  methods: {
    getFormattedDateTime,
  },
  computed: {
    id() {
      return this.$route.params.id
    },
  },
}
</script>
